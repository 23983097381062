<template>
  <UIInput
    type="text"
    :key="selectedText"
    v-bind="$attrs"
    v-model="selectedText"
    readonly
    :disabled="props.disabled"
    :placeholder="placeholder"
    :tooltip="tooltip(selected)"
    :class="[$attrs.class, { 'cursor-pointer': !disabled }, 'min-w-64 inline-block']"
    :style="`${selected?.disabled ? 'background: #f98080; color: white;' : ''}`"
    @focus="$event.target?.blur?.call($event.target) && open()"
    @mousedown.prevent.stop="open()"
    @click.self.prevent.stop="open()"
    @keypress.enter.passive="open()"
    @keypress.prevent.stop
    @keydown.prevent.stop
    @keyup.prevent.stop
  />

  <UIModal v-model="opened" :fixed="fixed" :close="!showToevoegenForm" close-text="Cancel">
    <div v-if="showToevoegenForm" class="w-full">
      <FormCustomOption
      v-model="toevoegenOption"
      :optionsTable="optionsTable"
      @close="showToevoegenForm = false"
      :callback="() => select(toevoegenOption)"
      />
      <!-- :callback="() => emit('update:options', [...options].concat([toevoegenOption]))" -->
      <button @click="() => showToevoegenForm = false" class="btn error mt-4 w-full mx-auto max-w-sm mx-auto block">Annuleren</button>
    </div>
    <div v-else :key="opened" class="border border-gray-200 rounded pb-0.5">
      <div class="p-1 w-full">
        <input
          :ref="(el) => searchInput = el"
          :placeholder="searchPlaceholder"
          class="input bg-white w-full"
          type="text"
          v-model="filter"
          @input="filter = $event?.target?.value || ''"
          @update:modelValue="hoverIndex = 0"
          @keyup.enter.passive="select(filteredItems[Math.max(hoverIndex, 0)])"
          @keyup.down.passive="hoverIndex = Math.min(filteredItems.length - 1, hoverIndex + 1)"
          @keyup.up.passive="hoverIndex = Math.max(0, hoverIndex - 1)"
        />
      </div>

      <div class="w-full overflow-y-scroll" style="max-height: 60vh">
        <ul class="block" :key="filteredItems.length">
          <li
            v-for="(item, index) in filteredItems"
            :key="`${index}${item.value}`"
            @click="select(item)"
            @mousemove.passive="hoverIndex = index"
            @mouseleave.passive="hoverIndex = -1"
            :tooltip="tooltip(item)"
            :class="{
              'bg-gray-100': !item.disabled && hoverIndex === index,
              'text-gray-400 line-through cursor-not-allowed': item.disabled,
            }"
            class="cursor-pointer select-none relative py-2 px-3 max-w-md"
          >
            <span :class="[isItemSelected(item) ? 'font-semibold' : 'font-normal', 'block truncate']">
              <template v-if="typeof formatLabel === 'function'">
                {{ formatLabel(item, item.label || item.text) }}
              </template>
              <template v-else>
                {{ item.label || item.text }}
              </template>
            </span>

            <span v-if="isItemSelected(item)" class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
              <i class="fas fa-check"></i>
            </span>
            <ExternalLink v-if="item.link" :href="item.link" class="text-blue-500 absolute right-10 inset-y-0 flex items-center pr-4" @click.stop>
              <i class="fas fa-info-circle"></i>
            </ExternalLink>
          </li>
        </ul>
      </div>

    </div>
    <button v-if="!showToevoegenForm && optionsTable" @click="() => handleShowCreateForm()" class="m-2">+ Nieuwe optie toevoegen</button>
  </UIModal>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, nextTick, watch, onMounted } from 'vue'
import UIModal from '@/components/UI/Modal.vue'
import UIInput from '@/components/UI/Input/Input.vue'
import ExternalLink from '@/components/UI/ExternalLink.vue'
import FormCustomOption from '@/components/Form/Modal/CustomOption.vue'

/*
  clickOutside: Boolean,
  modelValue: Boolean,
  fullWidth: Boolean,
  close: Boolean,
  confirm: Boolean,
  closeText: String,
  closeClass: String,
  confirmClass: String,
*/
const props = defineProps({
  placeholder: {
    type: String,
    default: 'Selecteer ...',
  },
  searchPlaceholder: {
    type: String,
    default: 'Zoeken / Filteren ...',
  },
  modelValue: {
    type: [String, Number, Array],
    default: null,
  },
  options: {
    type: Array,
    default() {
      return []
    },
  },
  formatLabel: Function,
  multiple: Boolean,
  fixed: Boolean,
  disabled: Boolean,
  optionsTable: String,
},
)

const emit = defineEmits(['update:modelValue', 'update:options'])

const noActions = ref(true)
const opened = ref(false)
const filter = ref('')
const searchInput = ref(null)
const selected = ref([])
const hoverIndex = ref(0)

const isItemSelected = (item) => {
  return selected.value?.length && selected.value.findIndex(el => el.value === item.value) > -1
}

const open = () => {
  if (props.disabled) return
  document.activeElement.blur()
  filter.value = ''
  noActions.value = true
  opened.value = true
  nextTick(() => {
    if (searchInput.value?.focus) searchInput.value.focus()
    // noActions/setTimeout to prevent double click to select an item immediately
    setTimeout(() => { noActions.value = false }, 350)
  })
}

const tooltip = item => {
  if (!item) return ''
  let res = item.label || item.text
  if (item.id || item.value) {
    res += ` (# ${item.id || item.value})`
  }
  return res
}

const selectedText = computed(() => {
  if (!selected?.value?.length) return ''

  const labels = selected?.value.reduce((result, item) => {
    let label = item.label || item.text
    if (label && typeof props.formatLabel === 'function') {
      label = props.formatLabel(item, label)
    }
    if (label) result.push(label)
    return result
  }, [])

  return labels.join(', ')
})

// Function Options: { force: Boolean }
const select = (item, options) => {
  if (!item) return
  if (!options?.force && noActions.value) return
  if (!options?.force && item.disabled) return
  if (props.multiple && isItemSelected(item)) {
    selected.value = selected.value.filter(optie => optie.value !== item.value)
  } else {
    if (!props.multiple || item.nullableItem || item.value === null) {
      selected.value = [item]
    } else {
      selected.value = selected.value.filter(el => !el.nullableItem && item.value !== null)
      selected.value.push(item)
    }
  }
  emit('update:modelValue', props.multiple ? selected.value : item.value)
  opened.value = false
}

const filteredItems = computed(() => {
  const options = props.options || []
  if (!filter.value) return options
  const term = String(filter.value).toLowerCase().replace(/\s+/gi, '')
  return options.filter(el =>
    String(el.label || '')
      .toLowerCase()
      .replace(/\s+/gi, '')
      .includes(term),
  )
})

const handleModelValue = () => {
  const options = props.options
  if (!options.length) return

  if (props.multiple && (props.modelValue === null || !props.modelValue?.length)) {
    const nullableItem = options.find((option) => option.nullableItem)
    if (nullableItem) {
      select(nullableItem, { force: true })
      return
    }
  }

  if (props.modelValue || props.modelValue === null) {
    const terms = Array.isArray(props.modelValue) ? props.modelValue : [String(props.modelValue)]
    options.forEach((option, index) => {
      if (!terms.includes(String(option.value))) return
      if (isItemSelected(option)) return
      select(props.options[index], { force: true })
      hoverIndex.value = index
    })
  }
}

onMounted(handleModelValue)
watch(() => props.modelValue, handleModelValue)
watch(() => props.options, handleModelValue)

const showToevoegenForm = ref(false)
const toevoegenOption = ref({ label: '', value: '' })

const handleShowCreateForm = () => {
  if (!props.optionsTable) return
  toevoegenOption.value.label = ''
  toevoegenOption.value.value = ''
  showToevoegenForm.value = true
}
</script>
