<template>
  <div>
    <div v-show="!isOffline && !isMaintenance">
      <UIVersionUpdater />
      <UINotificationsManager class="z-20" />
      <UIModalManager class="z-10" />
      <ViewLoading v-if="isLoading"></ViewLoading>
      <component v-else :is="layout" v-bind="layoutProps">
        <router-view v-bind="componentProps"></router-view>
      </component>
    </div>
    <LayoutCentered v-show="isOffline" class="overflow-hidden px-4">
      <p class="font-bold text-red-500 text-lg">U bent offline, verbind je aan het internet!</p>
      <p>Eenmaal online tonen we je de pagina opnieuw waar je hiervoor actief op was.</p>
      <p>Je hoeft de pagina NIET te herladen.</p>
    </LayoutCentered>
    <LayoutCentered v-show="isMaintenance" class="overflow-hidden px-4">
      <p class="font-bold text-red-500 text-lg">Dashboard API mogelijk niet toegankelijk</p>
      <p>Het is mogelijk dat we even het signaal naar onze API server zijn verloren.</p>
      <button class="btn bg-dashboard-dark text-white mt-4" @click="initialize">Opnieuw proberen</button>
    </LayoutCentered>
    <PortalTarget name="modals" multiple></PortalTarget>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, defineAsyncComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { PortalTarget } from 'portal-vue'
import { useStore } from 'vuex'
import useWebsocketHealth from '@/hooks/useWebsocketHealth'

import ViewLoading from './pages/_Loading.vue'
import LayoutDefault from './layouts/default.vue'
import LayoutCentered from './layouts/centered.vue'
import LayoutEmpty from './layouts/empty.vue'
import UIModalManager from '@/components/UI/Modal/Manager'
import UINotificationsManager from '@/components/UI/Notifications/Manager'
import hasRoutePermission from './functions/hasRoutePermission'
import { useNetwork } from '@vueuse/core'
import unregisterServiceWorkers from './functions/unregisterServiceWorkers'

const UIVersionUpdater = defineAsyncComponent(() => import('@/components/UI/Banner/VersionUpdater.vue'))

const store = useStore()
const router = useRouter()
const route = useRoute()
const { isOnline, isSupported } = useNetwork()

const layouts = {
  LayoutDefault,
  LayoutCentered,
  LayoutEmpty,
}

const layout = computed(() => layouts[`Layout${route.meta.layout || 'Default'}`])
const layoutProps = computed(() => route.meta.layoutProps || {})
const componentProps = computed(() => route.meta.componentProps || {})
// const routePath = computed(() => route.path)

const isLoading = ref(true)
const isMaintenance = ref(false)
const isOffline = computed(() => isSupported && !isOnline.value)

const initialize = async () => {
  const start = Date.now()

  isLoading.value = true
  isMaintenance.value = false

  await store
    .dispatch('CLIENT_INIT')
    .then(async ({ isAuthenticated }) => {
      await router.isReady()
      const noAuthenticationNeeded = layoutProps.value.noAuthenticationNeeded
      if (!isAuthenticated && !noAuthenticationNeeded) {
        return router.push(`/login?from=${route.path}`).catch(() => {})
      }
      if (isAuthenticated && route.name === 'Login') {
        return router.push('/').catch(() => {})
      }
      if (noAuthenticationNeeded) {
        return
      }
      if (route.meta?.permissions?.length && !hasRoutePermission(route)) {
        return router.push('/').catch(() => {})
      }
      return
    })
    .then(() => {
      const time = Math.max(60, 600 - (Date.now() - start))
      setTimeout(() => {
        isLoading.value = false
      }, time)
    })
    .catch((err) => {
      console.log({ err })
      isMaintenance.value = true
    })

  useWebsocketHealth((healthy) => {
    isMaintenance.value = !healthy
  })
}

onMounted(() => {
  initialize()
  unregisterServiceWorkers()
})
</script>
