import { createRouter, createWebHistory, START_LOCATION } from 'vue-router'
import store from './store'
import hasRoutePermission from './functions/hasRoutePermission'
import { capitalize } from '@/functions/formatText'
import useWindow from './hooks/useWindow'

const VERSIONS_ARBEIDSREGELEMENT = {
  '20220817': {
    page: 69,
    position: {
      x: 71,
      y: 490,
    },
    positionName: {
      x: 77,
      y: 672,
    },
  },
}

const routes = [
  {
    path: '/refresh',
    name: 'Refresh',
    meta: {
      layout: 'Centered',
      layoutProps: {
        noAuthenticationNeeded: true,
      },
    },
    component: () => import(/* webpackChunkName: "Refresh" */ './pages/Refresh.vue'),
  },
  {
    path: '/debug',
    name: 'Debug',
    component: () => import(/* webpackChunkName: "Debug" */ './pages/debug/index.vue'),
    meta: {
      permissions: ['DEBUG'],
    },
  },

  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ './pages/Home.vue'),
    meta: {
      permissions: ['CONNECTED'],
    },
  },
  {
    path: '/',
    name: 'Login',
    meta: {
      layout: 'Centered',
      layoutProps: {
        noAuthenticationNeeded: true,
      },
    },
    component: () => import(/* webpackChunkName: "Login" */ './pages/Login.vue'),
  },
  {
    path: '/chat/:hash',
    name: 'RittenChat',
    meta: {
      layout: 'Centered',
      layoutProps: {
        hideLogo: true,
        noAuthenticationNeeded: true,
      },
    },
    component: () => import(/* webpackChunkName: "RittenChat" */ './pages/chat/Chat.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'Centered',
      layoutProps: {
        noAuthenticationNeeded: true,
      },
    },
    component: () => import(/* webpackChunkName: "Login" */ './pages/Login.vue'),
  },
  {
    path: '/print/contracten',
    name: 'PrintContracten',
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
      layout: 'Empty',
      layoutProps: {
        // noAuthenticationNeeded: true,
      },
    },
    component: () => import(/* webpackChunkName: "contracten" */ './pages/contracten/print.vue'),
  },
  {
    path: '/sign/contract/:rit_id',
    name: 'SignContracten',
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR', 'CHAUFFEUR', 'ONDERAANNEMER'],
      layout: 'Empty',
      layoutProps: {
        // noAuthenticationNeeded: true,
      },
      componentProps: {
        apiSignFn: () => '/api/dashboard/signature/sign?subfolder=ritten',
        apiImageFn: () => '/api/dashboard/signature/image?subfolder=ritten',
      },
    },
    component: () => import(/* webpackChunkName: "signature" */ './pages/contracten/sign.vue'),
  },
  {
    path: '/chauffeur/arbeidsregelement',
    name: 'Arbeidsregelement',
    meta: {
      permissions: ['DEBUG', 'MASTER', 'KANTOOR', 'ADMIN', 'CHAUFFEUR'],
      componentProps: {
        versions: Object.keys(VERSIONS_ARBEIDSREGELEMENT),
      },
    },
    component: () => import(/* webpackChunkName: "Arbeidsregelement" */ './pages/chauffeur/Arbeidsregelement.vue'),
  },
  {
    path: '/sign/arbeidsregelement',
    redirect: '/chauffeur/arbeidsregelement',
  },
  {
    path: '/sign/arbeidsregelement/:version',
    name: 'SignArbeidsregelement',
    meta: {
      permissions: ['DEBUG', 'MASTER', 'KANTOOR', 'ADMIN', 'CHAUFFEUR'],
      layout: 'Empty',
      layoutProps: {
        // noAuthenticationNeeded: true,
      },
      componentProps: {
        apiTemplateFn: (route) => `/api/dashboard/signature/template?subfolder=arbeidsreglement/${route.params.version}`,
        apiSignFn: (route) => `/api/dashboard/signature/sign?subfolder=arbeidsreglement/${route.params.version}`,
        apiImageFn: (route) => `/api/dashboard/signature/image?subfolder=arbeidsreglement/${route.params.version}`,
        documentDataFn: (route) => VERSIONS_ARBEIDSREGELEMENT[route.params.version],
        title: 'Arbeidsregelement',
        redirect: true,
      },
    },
    component: () => import(/* webpackChunkName: "signature" */ './pages/signature/sign.vue'),
  },
  {
    path: '/sign/arbeidsregelement/:version/document',
    name: 'SignArbeidsregelementDocument',
    meta: {
      permissions: ['DEBUG', 'MASTER', 'KANTOOR', 'ADMIN', 'CHAUFFEUR'],
      layout: 'Empty',
      layoutProps: {
        // noAuthenticationNeeded: true,
      },
      componentProps: {
        apiTemplateFn: (route) => `/api/dashboard/signature/template?subfolder=arbeidsreglement/${route.params.version}`,
        apiSignFn: (route) => `/api/dashboard/signature/sign?subfolder=arbeidsreglement/${route.params.version}`,
        apiImageFn: (route) => `/api/dashboard/signature/image?subfolder=arbeidsreglement/${route.params.version}`,
        documentDataFn: (route) => VERSIONS_ARBEIDSREGELEMENT[route.params.version],
        title: 'Arbeidsreglement',
      },
    },
    component: () => import(/* webpackChunkName: "signature" */ './pages/signature/document.vue'),
  },
  {
    path: '/aanvaarden',
    name: 'AanvaardenOnderaanneming',
    meta: {
      layout: 'Centered',
      layoutProps: {
        noAuthenticationNeeded: true,
      },
    },
    component: () => import(/* webpackChunkName: "Aanvaarden" */ './pages/Aanvaarden.vue'),
  },
  {
    path: '/wachtwoord-wijzigen',
    name: 'WachtwoordWijzigen',
    component: () => import(/* webpackChunkName: "WachtwoordWijzigen" */ './pages/WachtwoordWijzigen.vue'),
    meta: {
      permissions: ['CONNECTED'],
    },
  },
  {
    path: '/chauffeur/planning',
    name: 'Mijn planning',
    component: () => import(/* webpackChunkName: "Chauffeur" */ './pages/chauffeur/Planning.vue'),
    meta: {
      permissions: ['DEBUG', 'ADMIN', 'KANTOOR', 'MASTER', 'CHAUFFEUR', 'ONDERAANNEMER'],
    },
  },
  {
    path: '/chauffeur/procedures',
    name: 'Procedures',
    component: () => import(/* webpackChunkName: "Chauffeur" */ './pages/chauffeur/Procedures.vue'),
    meta: {
      permissions: ['DEBUG', 'ADMIN', 'KANTOOR', 'MASTER', 'CHAUFFEUR', 'ONDERAANNEMER'],
    },
  },
  {
    path: '/chauffeur/reward',
    name: 'ChauffeurReward',
    component: () => import(/* webpackChunkName: "Chauffeur" */ './pages/chauffeur/Reward.vue'),
    meta: {
      permissions: ['DEBUG', 'ADMIN', 'KANTOOR', 'MASTER', 'CHAUFFEUR'],
    },
  },
  {
    path: '/chauffeur/afwezigheid',
    name: 'Mijn afwezigheden',
    component: () => import(/* webpackChunkName: "Chauffeur" */ './pages/chauffeur/beschikbaarheden/Afwezigheid.vue'),
    meta: {
      permissions: ['DEBUG', 'ADMIN', 'KANTOOR', 'MASTER', 'CHAUFFEUR', 'ONDERAANNEMER'],
    },
  },
  {
    path: '/chauffeur/aanwezigheid',
    name: 'Mijn aanwezigheden',
    component: () => import(/* webpackChunkName: "Chauffeur" */ './pages/chauffeur/beschikbaarheden/Aanwezigheid.vue'),
    meta: {
      permissions: ['DEBUG', 'ADMIN', 'KANTOOR', 'MASTER', 'CHAUFFEUR', 'ONDERAANNEMER'],
    },
  },
  {
    path: '/chauffeur/bestuurderspas',
    name: 'Mijn bestuurderspas',
    component: () => import(/* webpackChunkName: "Chauffeur" */ './pages/chauffeur/Bestuurderspas.vue'),
    meta: {
      permissions: ['DEBUG', 'ADMIN', 'KANTOOR', 'MASTER', 'CHAUFFEUR', 'ONDERAANNEMER'],
    },
  },
  {
    path: '/chauffeur/rijbewijs',
    name: 'Mijn rijbewijs',
    component: () => import(/* webpackChunkName: "Chauffeur" */ './pages/chauffeur/Rijbewijs.vue'),
    meta: {
      permissions: ['DEBUG', 'ADMIN', 'KANTOOR', 'MASTER', 'CHAUFFEUR', 'ONDERAANNEMER'],
    },
  },
  {
    path: '/chauffeur/onderhoud',
    name: 'Onderhoud',
    component: () => import(/* webpackChunkName: "Chauffeur" */ './pages/chauffeur/Onderhoud.vue'),
    meta: {
      permissions: ['DEBUG', 'ADMIN', 'KANTOOR', 'MASTER', 'CHAUFFEUR'],
    },
  },
  {
    path: '/ritten/info/:rit_id',
    name: 'RitInfo',
    component: () => import(/* webpackChunkName: "RitInfo" */ './pages/ritten/Info.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR', 'CHAUFFEUR'],
    },
  },
  {
    path: '/klanten/info/:klant_id',
    name: 'KlantInfo',
    component: () => import(/* webpackChunkName: "KlantInfo" */ './pages/klanten/Info.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'CHAUFFEUR'],
    },
  },
  {
    path: '/bedrijven/info/:facturatie_id',
    redirect: () => ({
      name: 'BedrijvenInfoBoekingen',
    }),
    name: 'BedrijvenInfo',
    component: () => import(/* webpackChunkName: "BedrijfInfo" */ './pages/bedrijven/Info/Index.vue'),
    children: [
      {
        path: '/bedrijven/info/:facturatie_id/boekingen',
        name: 'BedrijvenInfoBoekingen',
        component: () => import(/* webpackChunkName: "BedrijfInfo" */ './pages/bedrijven/Info/Boekingen.vue'),
        meta: {
          permissions: ['DEBUG', 'MASTER', 'ADMIN'],
        },
      },
      {
        path: '/bedrijven/info/:facturatie_id/rendabiliteit',
        name: 'BedrijvenInfoRendabiliteit',
        component: () => import(/* webpackChunkName: "BedrijfInfo" */ './pages/bedrijven/Info/Rendabiliteit.vue'),
        meta: {
          permissions: ['DEBUG', 'MASTER', 'ADMIN'],
        },
      },
    ],
  },
  {
    path: '/opvolging/boekingen',
    name: 'Boekingen',
    component: () => import(/* webpackChunkName: "Boekingen" */ './pages/opvolging/Boekingen.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/opvolging/voice',
    name: 'VoiceNotitie',
    component: () => import(/* webpackChunkName: "VoiceNotitie" */ './pages/opvolging/VoiceNotitie.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/opvolging/tracking',
    name: 'Tracking',
    component: () => import(/* webpackChunkName: "TrackingHeatmap" */ './pages/opvolging/Tracking.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/opvolging/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "Feedback" */ './pages/opvolging/Feedback.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/opvolging/gastenboek',
    name: 'Gastenboek',
    component: () => import(/* webpackChunkName: "Gastenboek" */ './pages/opvolging/Gastenboek.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/opvolging/mails',
    name: 'Mails',
    component: () => import(/* webpackChunkName: "Mails" */ './pages/opvolging/Mails.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/opvolging/enquetes',
    name: 'Enquetes',
    component: () => import(/* webpackChunkName: "Enquetes" */ './pages/opvolging/Enquetes.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/stats',
    name: 'Stats',
    redirect: '/stats/dashboard',
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/stats/heatmap',
    name: 'Heatmap',
    component: () => import(/* webpackChunkName: "TrackingHeatmap" */ './pages/stats/Heatmap.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/stats/dashboard',
    redirect: '/stats/dashboard/ritten/overzicht',
    name: 'StatsDashboard',
    component: () => import(/* webpackChunkName: "Stats" */ './pages/stats/Dashboard2/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
    children: [
      {
        path: '/stats/dashboard/ritten',
        redirect: '/stats/dashboard/ritten/overzicht',
        name: 'StatsDashboardRitten',
        component: () => import(/* webpackChunkName: "StatsDashboardRitten" */ './pages/stats/Dashboard2/Ritten/Index.vue'),
        children: [
          {
            path: '/stats/dashboard/ritten/overzicht',
            name: 'StatsDashboardRittenOverzicht',
            component: () => import(/* webpackChunkName: "StatsDashboardRitten" */ './pages/stats/Dashboard2/Ritten/Overzicht.vue'),
          },
          {
            path: '/stats/dashboard/ritten/maand',
            name: 'StatsDashboardRittenMaand',
            component: () => import(/* webpackChunkName: "StatsDashboardRitten" */ './pages/stats/Dashboard2/Ritten/Maand.vue'),
          },
          {
            path: '/stats/dashboard/ritten/jaar',
            name: 'StatsDashboardRittenJaar',
            component: () => import(/* webpackChunkName: "StatsDashboardRitten" */ './pages/stats/Dashboard2/Ritten/Jaar.vue'),
          },
        ],
      },
      {
        path: '/stats/dashboard/boekingen',
        redirect: '/stats/dashboard/boekingen/overzicht',
        name: 'StatsDashboardBoekingen',
        component: () => import(/* webpackChunkName: "StatsDashboardBoekingen" */ './pages/stats/Dashboard2/Boekingen/Index.vue'),
        children: [
          {
            path: '/stats/dashboard/boekingen/overzicht',
            name: 'StatsDashboardBoekingenOverzicht',
            component: () => import(/* webpackChunkName: "StatsDashboardBoekingen" */ './pages/stats/Dashboard2/Boekingen/Overzicht.vue'),
          },
          {
            path: '/stats/dashboard/boekingen/maand',
            name: 'StatsDashboardBoekingenMaand',
            component: () => import(/* webpackChunkName: "StatsDashboardBoekingen" */ './pages/stats/Dashboard2/Boekingen/Maand.vue'),
          },
          {
            path: '/stats/dashboard/boekingen/jaar',
            name: 'StatsDashboardBoekingenJaar',
            component: () => import(/* webpackChunkName: "StatsDashboardBoekingen" */ './pages/stats/Dashboard2/Boekingen/Jaar.vue'),
          },
        ],
      },
      {
        path: '/stats/dashboard/betalingen',
        name: 'StatsDashboardBetalingen',
        component: () => import(/* webpackChunkName: "StatsDashboardBetalingen" */ './pages/stats/Dashboard2/Betalingen.vue'),
      },
      {
        path: '/stats/dashboard/pipeline',
        name: 'StatsDashboardPipeline',
        component: () => import(/* webpackChunkName: "StatsDashboardPipeline" */ './pages/stats/Dashboard2/Pipeline.vue'),
      },
      {
        path: '/stats/dashboard/contacten',
        name: 'StatsDashboardContacten',
        component: () => import(/* webpackChunkName: "StatsDashboardContacten" */ './pages/stats/Dashboard2/Contacten.vue'),
      },
      {
        path: '/stats/dashboard/klanten',
        name: 'StatsDashboardKlanten',
        component: () => import(/* webpackChunkName: "StatsDashboardKlanten" */ './pages/stats/Dashboard2/Klanten.vue'),
      },
      {
        path: '/stats/dashboard/personeel',
        name: 'StatsDashboardPersoneel',
        component: () => import(/* webpackChunkName: "StatsDashboardPersoneel" */ './pages/stats/Dashboard2/Personeel.vue'),
      },
      {
        path: '/stats/dashboard/top-klanten',
        name: 'StatsDashboardTopKlanten',
        component: () => import(/* webpackChunkName: "StatsDashboardTopklanten" */ './pages/stats/Dashboard2/Topklanten.vue'),
      },
      {
        path: '/stats/dashboard/top-bedrijven',
        name: 'StatsDashboardTopBedrijven',
        component: () => import(/* webpackChunkName: "StatsDashboardTopBedrijven" */ './pages/stats/Dashboard2/TopBedrijven.vue'),
      },
      {
        path: '/stats/dashboard/betaaltermijnen',
        name: 'StatsDashboardBetaaltermijnen',
        component: () => import(/* webpackChunkName: "StatsDashboardTopBedrijven" */ './pages/stats/Dashboard2/Betaaltermijnen.vue'),
      },
    ],
  },
  {
    path: '/stats/enquetes',
    name: 'StatsEnquetes',
    component: () => import(/* webpackChunkName: "Stats" */ './pages/stats/Enquetes.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/stats/chauffeurs',
    name: 'StatsChauffeurs',
    component: () => import(/* webpackChunkName: "Stats" */ './pages/stats/Chauffeurs/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/stats/ritten',
    name: 'StatsRitten',
    component: () => import(/* webpackChunkName: "Stats" */ './pages/stats/Ritten/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/stats/affiliates',
    name: 'StatsAffiliates',
    component: () => import(/* webpackChunkName: "Stats" */ './pages/stats/Affiliates/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/stats/kosten',
    name: 'StatsKosten',
    component: () => import(/* webpackChunkName: "Stats" */ './pages/stats/Kosten/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/stats/promoties',
    name: 'StatsPromoties',
    component: () => import(/* webpackChunkName: "Stats" */ './pages/stats/Promoties/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/stats/wagens',
    name: 'StatsWagens',
    component: () => import(/* webpackChunkName: "Stats" */ './pages/stats/Wagens/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/afspraken/nieuw',
    name: 'AfsprakenNieuw',
    component: () => import(/* webpackChunkName: "AfsprakenForm" */ './pages/afspraken/Nieuw.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/afspraken/bewerken/:afspraak_id',
    name: 'AfsprakenBewerken',
    component: () => import(/* webpackChunkName: "AfsprakenForm" */ './pages/afspraken/Bewerken.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/kalender',
    alias: ['/ritten/kalender', '/afspraken/kalender'],
    name: 'Kalender',
    component: () => import(/* webpackChunkName: "Kalender" */ './pages/ritten/Kalender.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/kalender/maandelijks',
    alias: ['/ritten/kalender/maandelijks', '/afspraken/kalender/maandelijks'],
    name: 'KalenderMaandelijks',
    component: () => import(/* webpackChunkName: "Maandelijks" */ './pages/ritten/Maandelijks.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/offerte/nieuw',
    name: 'OfferteNieuw',
    component: () => import(/* webpackChunkName: "OfferteForm" */ './pages/offerte/Nieuw.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/offerte/bewerken/:rit_id',
    name: 'OfferteBewerken',
    component: () => import(/* webpackChunkName: "RittenForm" */ './pages/offerte/Bewerken.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/ritten/nieuw',
    name: 'RittenNieuw',
    component: () => import(/* webpackChunkName: "RittenForm" */ './pages/ritten/Nieuw.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/ritten/bewerken/:rit_id',
    name: 'RittenBewerken',
    component: () => import(/* webpackChunkName: "RittenForm" */ './pages/ritten/Bewerken.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/ritten/onbevestigd',
    name: 'Onbevestigd',
    component: () => import(/* webpackChunkName: "RittenTabel" */ './pages/ritten/Onbevestigd.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/ritten/VRP',
    name: 'RittenVRP',
    component: () => import(/* webpackChunkName: "RittenVRP" */ './pages/ritten/VRP.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/ritten/rendabiliteit',
    name: 'RittenRendabiliteit',
    component: () => import(/* webpackChunkName: "RittenRendabiliteit" */ './pages/ritten/Rendabiliteit.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/administratie/kasboek',
    name: 'AdministratieKasboek',
    component: () => import(/* webpackChunkName: "AdministratieKasboek" */ './pages/administratie/Kasboek.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/administratie/kosten',
    name: 'AdministratieKosten',
    component: () => import(/* webpackChunkName: "AdministratieKosten" */ './pages/administratie/Kosten.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/administratie/afrekenen',
    name: 'AdministratieAfrekenen',
    component: () => import(/* webpackChunkName: "AdministratieAfrekenen" */ './pages/administratie/Afrekenen.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'KANTOOR'],
    },
  },
  {
    path: '/administratie/cash-in-omloop',
    name: 'AdministratieCashInOmloop',
    component: () => import(/* webpackChunkName: "AdministratieCashInOmloop" */ './pages/administratie/CashInOmloop.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/administratie/afwezigheden',
    name: 'AdministratieAfwezigheden',
    component: () => import(/* webpackChunkName: "AdministratieAfwezigheden" */ './pages/administratie/beschikbaarheden/Afwezigheden.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/administratie/aanwezigheden',
    name: 'AdministratieAanwezigheden',
    component: () => import(/* webpackChunkName: "AdministratieAanwezigheden" */ './pages/administratie/beschikbaarheden/Aanwezigheden.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/administratie/betalingen',
    name: 'AdministratieBetalingen',
    component: () => import(/* webpackChunkName: "AdministratieBetalingen" */ './pages/administratie/Betalingen.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/administratie/shiften',
    name: 'AdministratieShiften',
    component: () => import(/* webpackChunkName: "AdministratieShiften" */ './pages/administratie/Shiften.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/administratie/vouchers',
    name: 'AdministratieVouchers',
    component: () => import(/* webpackChunkName: "AdministratieVouchers" */ './pages/administratie/vouchers/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/duplicaten/facturaties/:algo',
    name: 'DuplicatenFacturaties',
    component: () => import(/* webpackChunkName: "DuplicatenFacturaties" */ './pages/duplicaten/Facturaties.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/facturatie',
    alias: ['/facturatie/facturen', '/facturatie/overzicht'],
    name: 'FacturatieFacturen',
    redirect: '/facturatie/facturen/overzicht',
  },
  {
    path: '/facturatie/creditnotas',
    name: 'FacturatieCreditnotas',
    redirect: '/facturatie/creditnotas/overzicht',
  },
  {
    path: '/facturatie/facturen/maandelijks',
    name: 'FacturatieFacturenMaandelijks',
    component: () => import(/* webpackChunkName: "FacturatieOverzicht" */ './pages/facturatie/facturen/Maandelijks.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  ...(['Facturen', 'Creditnotas'].reduce((list, n, index) => {
    list.push(
      {
        path: `/facturatie/${n.toLowerCase()}/overzicht`,
        name: `Facturatie${capitalize(n)}Overzicht`,
        component: () => import(/* webpackChunkName: "FacturatieOverzicht" */ `./pages/facturatie/${n.toLowerCase()}/Overzicht.vue`),
        meta: {
          permissions: ['DEBUG', 'MASTER', 'ADMIN', 'BOEKHOUDER', 'KANTOOR'],
        },
      },
      {
        path: `/facturatie/${n.toLowerCase()}/nieuw/${index === 1 ? ':doc_number_linked' : ''}`,
        name: `Facturatie${capitalize(n)}Nieuw`,
        component: () => import(/* webpackChunkName: "FacturatieNieuw" */ `./pages/facturatie/${n.toLowerCase()}/Nieuw.vue`),
        meta: {
          permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
        },
      },
      {
        path: `/facturatie/${n.toLowerCase()}/bewerken/:doc_number`,
        name: `Facturatie${capitalize(n)}Bewerken`,
        component: () => import(/* webpackChunkName: "FacturatieBewerken" */ `./pages/facturatie/${n.toLowerCase()}/Bewerken.vue`),
        meta: {
          permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
        },
      },
    )
    return list
  }, [])),
  {
    path: '/facturatie/passagier-saldo',
    name: 'PassagierSaldo',
    component: () => import(/* webpackChunkName: "PassagierSaldo" */ './pages/facturatie/passagier-saldo/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/facturatie/nordigen',
    name: 'Nordigen',
    component: () => import(/* webpackChunkName: "Nordigen" */ './pages/facturatie/Nordigen/Index.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/facturatie/nordigen/:iban',
    name: 'NordigenIban',
    component: () => import(/* webpackChunkName: "NordigenIban" */ './pages/facturatie/Nordigen/Iban.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/platform/geocoding',
    name: 'PlatformGeocoding',
    component: () => import(/* webpackChunkName: "PlatformGeocoding" */ './pages/platform/Geocoding.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/platform/views',
    name: 'PlatformViews',
    component: () => import(/* webpackChunkName: "PlatformViews" */ './pages/platform/views/List.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/platform/notificaties/nieuw',
    name: 'PlatformNotificatiesNieuw',
    component: () => import(/* webpackChunkName: "PlatformNotificatiesNieuw" */ './pages/platform/notificaties/Nieuw.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/platform/nieuwsartikels',
    name: 'PlatformNieuwsartikels',
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
    component: () => import(/* webpackChunkName: "PlatformNieuwsartikels" */ './pages/platform/nieuwsartikels/List.vue'),
  },
  {
    path: '/platform/werkdagen',
    name: 'PlatformWerkdagen',
    meta: {
      is_active: true,
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
    component: () => import(/* webpackChunkName: "PlatformWerkdagen" */ './pages/platform/werkdagen/List.vue'),
  },
  {
    path: '/platform/users',
    name: 'PlatformUsersActief',
    component: () => import(/* webpackChunkName: "PlatformTables" */ './pages/platform/users/List.vue'),
    meta: {
      is_active: true,
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/platform/users/inactief',
    name: 'PlatformUsersInactief',
    component: () => import(/* webpackChunkName: "PlatformTables" */ './pages/platform/users/List.vue'),
    meta: {
      is_active: false,
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/platform/users/rendabiliteit',
    name: 'PlatformUsersRendabiliteit',
    component: () => import(/* webpackChunkName: "PlatformUsersRendabiliteit" */ './pages/platform/users/Rendabiliteit.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/platform/wagens',
    name: 'PlatformWagensActief',
    component: () => import(/* webpackChunkName: "PlatformTables" */ './pages/platform/wagens/List.vue'),
    meta: {
      is_active: true,
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/platform/wagens/inactief',
    name: 'PlatformWagensInactief',
    component: () => import(/* webpackChunkName: "PlatformTables" */ './pages/platform/wagens/List.vue'),
    meta: {
      is_active: false,
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
  },
  {
    path: '/platform/blacklists',
    name: 'PlatformBlacklists',
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN', 'KANTOOR'],
    },
    component: () => import(/* webpackChunkName: "PlatformBlacklists" */ './pages/platform/blacklists/Page.vue'),
  },
  {
    path: '/platform/affiliates',
    name: 'PlatformAffiliates',
    component: () => import(/* webpackChunkName: "PlatformTables" */ './pages/platform/affiliates/List.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER'],
    },
  },
  {
    path: '/platform/vips',
    name: 'VIP\'s',
    component: () => import(/* webpackChunkName: "VIPs" */ './pages/platform/VIPs.vue'),
    meta: {
      permissions: ['DEBUG', 'MASTER', 'ADMIN'],
    },
  },
  {
    path: '/account',
    redirect: '/account/profiel',
    meta: {
      permissions: ['DEBUG', 'KLANT'],
    },
  },
  {
    path: '/account/profiel',
    name: 'AccountProfiel',
    component: () => import(/* webpackChunkName: "AccountProfiel" */ './pages/account/Profiel.vue'),
    meta: {
      permissions: ['DEBUG', 'KLANT'],
    },
  },
  {
    path: '/account/vouchers',
    name: 'AccountVouchers',
    component: () => import(/* webpackChunkName: "AccountVouchers" */ './pages/account/Vouchers.vue'),
    meta: {
      permissions: ['DEBUG', 'KLANT'],
    },
  },
  {
    path: '/account/gastenboek',
    name: 'AccountGasteboek',
    component: () => import(/* webpackChunkName: "AccountGasteboek" */ './pages/account/Gastenboek.vue'),
    meta: {
      permissions: ['DEBUG', 'KLANT'],
    },
  },
  {
    path: '/account/affiliate',
    name: 'AccountAffiliate',
    component: () => import(/* webpackChunkName: "AccountAffiliate" */ './pages/account/Affiliate.vue'),
    meta: {
      permissions: ['DEBUG', 'KLANT'],
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  if (from === START_LOCATION && useWindow()) {
    const loc = window.location
    if (loc.host.includes('localhost') && process.env.VUE_APP_BASE_URL_AXIOS) {
      const axiosURL = new URL(process.env.VUE_APP_BASE_URL_AXIOS)
      window.location.href = `http://${axiosURL.hostname}:${loc.port || 8080}${loc.pathname}${loc.search || ''}`
      return
    }
  }

  if (from !== START_LOCATION && to !== START_LOCATION && to?.meta?.permissions?.length && !hasRoutePermission(to, store)) {
    return router.resolve({ path: '/' })
  }

  if (to?.path.substring(0, 5) === '/api/') {
    const url = new URL(`${process.env.VUE_APP_BASE_URL_AXIOS}${to.path}`)
    Object.entries(to.query).forEach(([k, v]) => {
      url.searchParams.set(k, v)
    })
    window.location.href = url.toString()
    return false
  }

  if (to && from && to.name != from.name) {
    if (typeof document === 'undefined') return
    document.querySelectorAll('.pac-container').forEach(el => el.remove())
    document.querySelectorAll('.datepicker-modal').forEach(el => el.remove())
    document.querySelectorAll('.timepicker-modal').forEach(el => el.remove())
    document.querySelectorAll('.dropdown-content.select-dropdown').forEach(el => el.remove())
  }

  if (from.fullPath && from.fullPath !== '/' && !to.query?.from) {
    if (to?.path.substring(0, 16) === '/ritten/bewerken') {
      to.query.from = from.fullPath
      return to
    }
    if (to?.path.substring(0, 17) === '/offerte/bewerken') {
      to.query.from = from.fullPath
      return to
    }
  }

  // ...
  // explicitly return false to cancel the navigation
  return true
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
