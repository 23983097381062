
import axios from 'axios'
import { ref } from 'vue'

const currentVersion = ref(null)
const buildVersion = String(process.env.VUE_APP_BUILD_VERSION || '').replace(/\s/g, '').trim()

const setCurrentVersion = (version) => {
  currentVersion.value = String(version || '').replace(/\s/g, '').trim()
}

export const getCurrentVersion = async () => {
  return axios.get(`https://dashboard.taxiboeken.be/build.txt?_t=${Date.now()}`).then(({ data }) => {
    setCurrentVersion(data)
    return data
  }).catch((error) => {
    setCurrentVersion('?')
    console.error({ error })
    return '?'
  })
}

export default async () => {
  if (!currentVersion.value) {
    await getCurrentVersion()
  }

  return (
    !currentVersion.value
    || !buildVersion
    || buildVersion?.toLowerCase() === 'development'
    || (String(currentVersion.value) == buildVersion)
  )
}
